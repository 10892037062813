.Bubble {
  min-width: 300px;
  width: 338px;
  max-width: 338px;
  height: 455px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #FFF;
  color: #000;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
  z-index: 100;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.Bubble .header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #CCC;
  padding: 8px;
}
.Bubble .header .realtor {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.Bubble .header .realtor .avatar {
  border-radius: 50%;
}
.Bubble .header .realtor .name {
  margin-left: 8px;
}
.Bubble .header .controls .btn-close {
  width: 15px;
  height: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 50%;
  font-size: 15px;
  cursor: pointer;
}
.Bubble .messages {
  width: 100%;
  min-height: 349px;
  height: 349px;
  max-height: 349px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 32px;
  padding-right: 16px;
}
.Bubble .messages .message {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 6px;
}
.Bubble .messages .message.me {
  justify-content: flex-end;
}
.Bubble .messages .message.me .bubble {
  padding-right: 10px;
  background-color: #0084ff;
  color: #EEE;
  text-align: right;
}
.Bubble .messages .message.other {
  justify-content: flex-start;
}
.bubble {
  
  word-break: break-word;
}
.Bubble .messages .message.other .bubble {
  padding-left: 10px;
  text-align: left;
}
.Bubble .messages .message.group {
  margin-top: 2px;
}
.Bubble .messages .message.group.me .bubble {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.Bubble .messages .message.group.other .bubble {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.Bubble .messages .message.last-group.me .bubble {
  border-bottom-right-radius: 18px;
}
.Bubble .messages .message.last-group.other .bubble {
  border-bottom-left-radius: 18px;
}
.Bubble .messages .message.first-group.me .bubble {
  border-bottom-right-radius: 4px;
}
.Bubble .messages .message.first-group.other .bubble {
  border-bottom-left-radius: 4px;
}
.Bubble .messages .message .bubble {
  min-width: 35px;
  max-width: 70%;
  padding: 8px;
  border-radius: 18px;
  background-color: #CCC;
}
.Bubble > .controls {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 8px;
}
.Bubble > .controls input {
  outline: none;
  border: none;
  width: calc(100% - 36px);
  padding: 8px 0;
  padding-left: 16px;
  border-radius: 27px;
  background-color: #f0f2f5;
  color: #000;
  font-size: 14px;
}
.Bubble > .controls .btn-send {
  fill: #f0f2f5;
  cursor: pointer;
}
.Bubble:focus-within > .controls .btn-send {
  fill: #0084ff;
}

body.theme-dark .Bubble {
  background-color: #242526;
  color: #EEE;
}
body.theme-dark .Bubble .header {
  border-bottom: 1px solid #999;
}
body.theme-dark .Bubble .messages .message.me .bubble {
  background-color: #0084ff;
  color: #EEE;
}
body.theme-dark .Bubble .messages .message .bubble {
  background-color: #3a3b3c;
}
body.theme-dark .Bubble > .controls input {
  background-color: #3a3b3c;
  color: #EEE;
}
body.theme-dark:focus-within > .controls .btn-send {
  fill: #0084ff;
}/*# sourceMappingURL=Bubble.css.map */