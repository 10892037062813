@import './variables.scss';

.PropertyDetails {
  
  .description {
    color: #717171;
  }

  .characteristis {

    .header {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      font-size: $font-size-lg;

      span {
        color: #333;
      }

      div {
        color: #717171;
      }
    }

    .list {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 8px;

      .labels {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 8px;
        color: #111;
        flex-grow: 1;

        & > div {
          width: 100%;
          padding: 4px;
          border-radius: 2px;
          
          &:nth-child(odd) {
            background-color: #FFF;
          }

          &:nth-child(even) {
            background-color: #FBFBFB;
          }
        }
      }

      .values {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 8px;
        color: #777;
        flex-grow: 3;

        & > div {
          width: 100%;
          padding: 4px;
          border-radius: 2px;
          
          &:nth-child(odd) {
            background-color: #FFF;
          }

          &:nth-child(even) {
            background-color: #FBFBFB;
          }
        }
      }
    }
  }

  .extras {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 4px;
    margin-top: 16px;
  }
}

body.theme-dark {
  .PropertyDetails {

    .characteristis {

      .list {

        .labels {
          
          & > div {
            width: 100%;
            padding: 4px;
            
            &:nth-child(odd) {
              background-color: #293145;
              color: #EEE;
            }

            &:nth-child(even) {
              background-color: #1B243D;
              color: #EEE;
            }
          }
        }

        .values {
          
          & > div {
            width: 100%;
            padding: 4px;
            
            &:nth-child(odd) {
              background-color: #293145;
              color: #EEE;
            }

            &:nth-child(even) {
              background-color: #1B243D;
              color: #EEE;
            }
          }
        }
      }
    }
  }
}