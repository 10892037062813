.hover-card .card-image {
  overflow: hidden;
  display: flex;
  align-items: center;
}
.hover-card .card-image img {
  transition: all 0.4s ease !important;
  position: relative;
  width: 100%;
}
.hover-card .card-image:before {
  content: "";
  padding-top: 100%;
  float: left;
}
.hover-card:hover .card-image img {
  transform: scale(1.1);
}/*# sourceMappingURL=main.css.map */