.PropertyDetails .description {
  color: #717171;
}
.PropertyDetails .characteristis .header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 25px;
}
.PropertyDetails .characteristis .header span {
  color: #333;
}
.PropertyDetails .characteristis .header div {
  color: #717171;
}
.PropertyDetails .characteristis .list {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 8px;
}
.PropertyDetails .characteristis .list .labels {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  color: #111;
  flex-grow: 1;
}
.PropertyDetails .characteristis .list .labels > div {
  width: 100%;
  padding: 4px;
  border-radius: 2px;
}
.PropertyDetails .characteristis .list .labels > div:nth-child(odd) {
  background-color: #FFF;
}
.PropertyDetails .characteristis .list .labels > div:nth-child(even) {
  background-color: #FBFBFB;
}
.PropertyDetails .characteristis .list .values {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  color: #777;
  flex-grow: 3;
}
.PropertyDetails .characteristis .list .values > div {
  width: 100%;
  min-height: 49px;
  padding: 4px;
  border-radius: 2px;
}
.PropertyDetails .characteristis .list .values > div:nth-child(odd) {
  background-color: #FFF;
}
.PropertyDetails .characteristis .list .values > div:nth-child(even) {
  background-color: #FBFBFB;
}
.PropertyDetails .extras {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 16px;
}

body.theme-dark .PropertyDetails .characteristis .list .labels > div {
  width: 100%;
  padding: 4px;
}
body.theme-dark .PropertyDetails .characteristis .list .labels > div:nth-child(odd) {
  background-color: #293145;
  color: #EEE;
}
body.theme-dark .PropertyDetails .characteristis .list .labels > div:nth-child(even) {
  background-color: #1B243D;
  color: #EEE;
}
body.theme-dark .PropertyDetails .characteristis .list .values > div {
  width: 100%;
  padding: 4px;
}
body.theme-dark .PropertyDetails .characteristis .list .values > div:nth-child(odd) {
  background-color: #293145;
  color: #EEE;
}
body.theme-dark .PropertyDetails .characteristis .list .values > div:nth-child(even) {
  background-color: #1B243D;
  color: #EEE;
}/*# sourceMappingURL=PropertyDetails.css.map */