@font-face {
  src: url("../../public/assets/fonts/SairaStencilOne-Regular.ttf");
  font-family: "Saira Stencil One";
}
.fs-xs {
  font-size: 11px;
}

.text-default {
  color: #333 !important;
}

.text-italic {
  font-style: italic;
}

.badge-gray {
  background-color: #BBB;
  color: #666;
}

body.theme-dark .text-default {
  color: #EEE !important;
}

.app-logo {
  max-width: 100%;
  display: block;
  justify-content: center;
}

.font-size-30 {
  font-size: 30px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.img-circle {
  border-radius: 50%;
}

.skeleton-shimmer::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.4) 60%, rgba(255, 255, 255, 0));
  -webkit-animation: shimmer 2s infinite;
          animation: shimmer 2s infinite;
  content: "";
}
@-webkit-keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.white-space-no-wrap {
  white-space: nowrap;
}

.ttoast {
  position: fixed;
  left: 0;
  bottom: 0;
  margin: 2vh;
  padding: 20px;
  border-radius: 10px;
  z-index: 999;
  background-color: #EEE;
  color: #333;
}
.ttoast .content img {
  margin-right: 5px;
}
.ttoast .button-close {
  outline: none;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 3px;
  right: 3px;
  background-color: transparent;
  color: #333;
  font-size: 25px;
  cursor: pointer;
}

body.theme-dark input {
  color: #eee !important;
}
body.theme-dark .ttoast {
  background-color: #1a2236;
  color: #EEE;
}
body.theme-dark .ttoast .button-close {
  color: #EEE;
}

.button-disabled {
  filter: grayscale(0.5);
}

.user-select-none {
  -ms-user-select: none;
      user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}

.notifications-dropdown {
  min-width: 27rem;
  max-height: calc(100vh - 96px - 10px);
  overflow-y: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.notifications-dropdown::-webkit-scrollbar {
  width: 10px;
}
.notifications-dropdown::-webkit-scrollbar-track {
  background: #FFF;
}
.notifications-dropdown::-webkit-scrollbar-thumb {
  background: #CCC;
  border-radius: 10px;
}
.notifications-dropdown::-webkit-scrollbar-thumb:hover {
  background: #CCC;
}

.notification--friend-request-item .image, .notification--network-request-item .image, .notification--avatar .image {
  position: relative;
}
.notification--friend-request-item .image .notification-type-icon, .notification--network-request-item .image .notification-type-icon, .notification--avatar .image .notification-type-icon {
  position: absolute;
  right: -3px;
  bottom: -3px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px;
  border-radius: 50%;
  background-color: #FFF;
}
.notification--friend-request-item:hover .btn-menu, .notification--network-request-item:hover .btn-menu, .notification--avatar:hover .btn-menu {
  visibility: visible;
}
.notification--friend-request-item .btn-menu, .notification--network-request-item .btn-menu, .notification--avatar .btn-menu {
  position: relative;
  visibility: hidden;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.notification--friend-request-item .btn-menu.active, .notification--network-request-item .btn-menu.active, .notification--avatar .btn-menu.active {
  visibility: visible;
}
.notification--friend-request-item .btn-menu .menu, .notification--network-request-item .btn-menu .menu, .notification--avatar .btn-menu .menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  right: 0;
  padding: 8px;
  border-radius: 7px;
  background-color: #EEE;
  z-index: 999;
}
.notification--friend-request-item .btn-menu .menu.menu-left, .notification--network-request-item .btn-menu .menu.menu-left, .notification--avatar .btn-menu .menu.menu-left {
  right: unset !important;
  left: 0;
}
.notification--friend-request-item .btn-menu .menu.open, .notification--network-request-item .btn-menu .menu.open, .notification--avatar .btn-menu .menu.open {
  visibility: visible;
}
.notification--friend-request-item .btn-menu .menu > *, .notification--network-request-item .btn-menu .menu > *, .notification--avatar .btn-menu .menu > * {
  width: 100%;
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
  white-space: nowrap;
}
.notification--friend-request-item .btn-menu .menu > *:hover, .notification--network-request-item .btn-menu .menu > *:hover, .notification--avatar .btn-menu .menu > *:hover {
  background-color: #FFF;
}

body.theme-dark .ChatList .list .item.new-message {
  background-color: #2196f3;
}
body.theme-dark .dropdown-menu {
  background-color: #293145;
}
body.theme-dark .notification--friend-request-item .btn-menu i, body.theme-dark .notification--network-request-item .btn-menu i {
  color: #EEE;
}
body.theme-dark .notification--friend-request-item .btn-menu .menu, body.theme-dark .notification--network-request-item .btn-menu .menu {
  background-color: #293145;
  color: #EEE;
}
body.theme-dark .notification--friend-request-item .btn-menu .menu > *:hover, body.theme-dark .notification--network-request-item .btn-menu .menu > *:hover {
  background-color: #424C64;
}

.button {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}
.button.button--reject {
  background-color: #DDD;
  color: #333;
}

body.theme-dark .button--reject {
  background-color: #444;
  color: #EEE;
}

.ChatList {
  position: fixed;
  width: 300px;
  height: calc(100vh - 96px);
  top: 96px;
  right: 0;
  z-index: 99;
  background-color: #FFF;
  box-shadow: -2px 0 3px -2px #1a2236;
}
.ChatList .list {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 8px;
}
.ChatList .list .item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 4px;
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
}
.ChatList .list .item:hover {
  background-color: #EEE;
}
.ChatList .list .item.new-message {
  background-color: #EEE;
}
.ChatList .list .item .avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.ChatList .list .item .name {
  margin-left: 8px;
}

body.theme-dark .ChatList {
  background-color: #1a2236;
}
body.theme-dark .ChatList .list .item {
  color: #EEE;
}
body.theme-dark .ChatList .list .item:hover {
  background-color: #132977;
}
@-webkit-keyframes ldio-hjppnckp3cn {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes ldio-hjppnckp3cn {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.ldio-hjppnckp3cn div {
  position: absolute;
  width: 60px;
  height: 60px;
  border: 10px solid #ffffff;
  border-top-color: transparent;
  border-radius: 50%;
}

.ldio-hjppnckp3cn div {
  -webkit-animation: ldio-hjppnckp3cn 1s linear infinite;
          animation: ldio-hjppnckp3cn 1s linear infinite;
  top: 50px;
  left: 50px;
}

.loadingio-spinner-rolling-wcum84rzqc {
  width: 15px;
  height: 15px;
  display: inline-block;
  overflow: hidden;
  background: none;
}

.ldio-hjppnckp3cn {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.15);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}

.ldio-hjppnckp3cn div {
  box-sizing: content-box;
}

#map {
  height: 100%;
}

.property-card {
  height: 100%;
}

.property-card .card-image a {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2754e6;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2754e6;
}

input:checked + .slider:before {
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

body.theme-dark .slider {
  background-color: #555;
}
body.theme-dark input:checked + .slider {
  background-color: #2754e6;
}
body.theme-dark .text-black-50 {
  color: #eee !important;
}

.custom-sso-img {
  padding: 10px;
  background: #fff;
  border-radius: 5px;
  width: 86px !important;
}

.networks-wrapper, .agent-wrapper {
  color: #eee;
  margin: 10px;
  max-width: calc(100% - 70px);
  box-sizing: border-box;
}
.networks-wrapper .network-element, .agent-wrapper .network-element {
  font-size: 12px;
  display: block;
  color: #fff;
}
.networks-wrapper .network-element .network-logo, .agent-wrapper .network-element .network-logo {
  height: 20px;
  margin: 5px;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 7px;
  max-width: 60px;
}
.networks-wrapper .agent-logo, .agent-wrapper .agent-logo {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.mh-180 {
  min-height: 180px;
}

.main-name-wrapper {
  margin-left: 1rem;
  margin-bottom: 0;
}

.card-space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.btn-action {
  border-radius: 50px !important;
  letter-spacing: 0.1rem;
  border: none !important;
}

.btn-action.btn-leave {
  background-color: #d34141 !important;
}

.btn-action.btn-join {
  background-color: #5041d3 !important;
}

.p-relative {
  position: relative !important;
}

.system-message {
  text-align: center;
  background: transparent !important;
}
.system-message span {
  padding: 5px 10px;
  background: rgba(68, 68, 68, 0.3764705882);
  color: #BBB;
  border-radius: 50px;
  font-size: 12px;
}

.controls .system-message {
  position: absolute;
  width: 100%;
  top: -40px;
}

.mh-125 {
  min-height: 125px !important;
}/*# sourceMappingURL=style.css.map */

@media (max-width:425px) {
   /* .cross-data-wrapper .card {
    padding-left: 0px !important;
    width: 100% !important;
    padding-right: 0px !important;
   }
   .cross-data-wrapper .card img {
    position: relative !important;
    margin-left: 0px !important;
   } */
   .center-title:before, .center-title:after {
      width: 15% !important;
   }
}
@media screen and (max-width: 992px) {
  .toggle-darkmode-label, #toggle-darkmode {
    display: none;
  }
}