.nav-content-bttn.open-font span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.btn-locale {
  background: none;
  color: #9f9f9f;
  font-size: 20px;
}

.post-comments.show{
    display: block;
}

.post-comments.hide{
    display: none;
}