//@import "~slick-carousel/slick/slick.css";
//@import "~slick-carousel/slick/slick-theme.css";
//@import '~video-react/dist/video-react.css';

.hover-card {
    .card-image {
        overflow: hidden;
        display: flex;
        align-items: center;
        img {
            transition: all 0.4s ease !important;
            position: relative;
            width: 100%;
        }
        &:before{
            content: "";
            padding-top: 100%;
            float: left;
        }
    }
    &:hover .card-image img {
        transform: scale(1.1);
    }
  }