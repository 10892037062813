.Chat {
  position: fixed;
  min-width: 100vw;
  width: 100vw;
  max-height: 100vw;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  z-index: 999;

  .Bubble {
    
    &:nth-of-type(1) {
      margin-right: 80px;
    }
  }
}